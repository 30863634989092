
// src/App.tsx
import 'aos/dist/aos.css';
import { Hero } from '../components/hero';
import ValueProposition from '../components/value-proposition';
import VideoSection from '../components/video-section';
import FeatureMatching from '../components/feature-matching';
import JobPosition from '../components/job-position';
import CandidateInterview from '../components/candidate-interview';
import CandidateArchive from '../components/candidate-archive';
import MoreFeatures from '../components/more-features';
import TextDivider from '../components/text-devider';
import ContactUs from '../components/contact-us';
import Faq from '../components/faq';
import Footer from '../components/footer';
import { useIntl } from 'react-intl';

function Home() {
    const intl = useIntl();

    return (
        <>
            <Hero products={[
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/candidate-interview.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/profile.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/create-position.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/cv-detail.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/interview-card.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/interview-list.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/semantic-search.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/login.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/matching-list.png"
                },
                {
                    title: "",
                    link: "#contacts",
                    thumbnail: "assets/screens/positions-list.png"
                },
            ]} />
            <ValueProposition />
            <VideoSection />
            <FeatureMatching />
            <JobPosition />
            <CandidateInterview />
            <CandidateArchive />
            <MoreFeatures />
            <TextDivider
                source="Business Standard"
                sourceUrl="https://www.business-standard.com/india-news/92-feel-ai-has-made-hr-role-more-strategic-since-last-year-report-123100400677_1.html"
                message={intl.formatMessage({ id: 'textDivider.message1', defaultMessage: '80% of global HR professionals believe that AI will be crucial for their work in the next 5 years.' })} />
            <ContactUs />
            <TextDivider
                source="CareerBuilder"
                sourceUrl="https://resources.careerbuilder.com/news-research/prevent-hiring-the-wrong-person"
                message={intl.formatMessage({ id: 'textDivider.message2', defaultMessage: '75% of companies have hired the wrong candidate, incurring an average cost of over €17,000.' })} />
            <Faq />
            <Footer />
        </>

    );
}

export default Home;

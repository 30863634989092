import { FormattedMessage, useIntl } from 'react-intl';
import PrimaryButton from './primary-button';

const VideoSection = () => {
    const intl = useIntl();

    return (
        <section className="relative w-full h-auto bg-royalblue flex flex-col items-end justify-start py-16 xl:py-32 px-0 mt-[-45px] overflow-x-hidden">
            <div className="px-4 md:px-10 flex flex-col xl:flex-row flex-wrap items-start justify-start gap-[10vw] text-left text-white">
                <div className="flex-1 flex flex-col items-start justify-start pt-6 max-w-full">
                    <h2 className="text-[36px] xl:text-[60px] font-bold mb-2">
                        <FormattedMessage id="videoSection.objective" defaultMessage="Our Goal:" />
                        <p className='text-[26px]'>
                            <FormattedMessage id="videoSection.objectiveDescription" defaultMessage="To speed up, simplify, and optimize your hiring process." />
                        </p>
                    </h2>
                    <div className="flex flex-col items-start justify-start gap-4 max-w-full text-lg">
                        <b>
                            <FormattedMessage id="videoSection.how" defaultMessage="How do we do it?" />
                        </b>
                        <b>
                            <FormattedMessage id="videoSection.howDescription" defaultMessage="We are not an ATS, but a comprehensive tool, designed to be your co-pilot for Talent Acquisition." />
                        </b>                        <div className="flex flex-col items-start justify-start gap-8 mt-4">
                            <div className="flex items-top gap-24 pt-12">
                                <a href="#contacts" className="pt-4 lg:pt-0 cursor-pointer text-current no-underline hover:no-underline focus:no-underline hover:text-royalblue">
                                    <PrimaryButton
                                        lineRoundedSearch="/line-roundedsearch.svg"
                                        icon="/line-roundedarrow-right.svg"
                                        buttonText={intl.formatMessage({ id: "videoSection.button.requestDemo", defaultMessage: "Request a demo" })}
                                        color="#20231d"
                                        background="white"
                                    />
                                </a>
                                <img className="hidden xl:block w-[20vw]"
                                    data-aos="fade-right"
                                    data-aos-offset="200"
                                    data-aos-delay="50"
                                    data-aos-duration="1000"
                                    src="assets/up-white-arrow.svg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 relative max-w-full p-[20px] rounded-[120px] overflow-hidden">
                    <img src="assets/boost-kpi.svg" className="w-full" />
                </div>
            </div>
        </section >
    );
};

export default VideoSection;

import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { convertFirestoreTimestampToDate } from '../utils/date-utils';
import { useIntl } from 'react-intl';
import { FaMapMarkerAlt, FaRegCalendarAlt } from 'react-icons/fa';
import { white } from 'tailwindcss/colors';

interface Job {
    title: string;
    date: string;
    city: string;
    link: string;
}

interface QueryParams {
    borderRadius?: string;
    color?: string;
    displayMode?: 'card' | 'table';
}

const JobWidget: React.FC = () => {
    const { customerId } = useParams<{ customerId: string }>();
    const location = useLocation();
    const queryParams = queryString.parse(location.search) as QueryParams;

    const [jobs, setJobs] = useState<Job[]>([]);
    const [lastDocId, setLastDocId] = useState<string | null>(null);
    const [prevLastDocId, setPrevLastDocId] = useState<string | null>(null);
    const [hasMoreJobs, setHasMoreJobs] = useState<boolean>(true);
    const itemsPerPage = 25;
    const intl = useIntl();

    const apiUrl = process.env.REACT_APP_API_URL + "/getJobPostsByCompanyId";
    const apiKey = process.env.REACT_APP_GET_JOBPOSTS_SERVER_API_KEY;

    const borderRadius = queryParams.borderRadius || '0px';
    const backgroundColor = queryParams.color || '#f9f9f9';
    const displayMode = queryParams.displayMode || 'card';

    const fetchJobs = async () => {
        try {
            const response = await fetch(`${apiUrl}?companyId=${customerId}&lastDocId=${lastDocId}&limit=${itemsPerPage}`, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                }
            });
            const fetchedJobs = await response.json();
            const formattedJobs = fetchedJobs.jobPosts.map((job: any) => ({
                ...job,
                date: convertFirestoreTimestampToDate(job.date._seconds, job.date._nanoseconds).toLocaleDateString(),
            }));

            setJobs(prevJobs => {
                const existingJobIds = new Set(prevJobs.map(j => j.link));
                return [...prevJobs, ...formattedJobs.filter((job: { link: string; }) => !existingJobIds.has(job.link))];
            });

            if (fetchedJobs.lastDocId) {
                if (fetchedJobs.lastDocId === prevLastDocId) {
                    setHasMoreJobs(false);
                } else {
                    setPrevLastDocId(lastDocId);
                    setLastDocId(fetchedJobs.lastDocId);
                }
            } else {
                setHasMoreJobs(false);
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    };

    useEffect(() => {
        fetchJobs();
    }, [customerId]);

    const loadMore = () => {
        fetchJobs();
    };

    const renderCard = (job: Job) => (
        <div key={job.link} className="bg-white shadow-lg overflow-hidden p-4" style={{ borderRadius }}>
            <div className="h-full flex flex-col justify-between">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">{job.title}</h2>
                <div className='flex flex-col items-center'>
                    <div className="text-sm text-gray-600 mb-1">
                        <div className="flex items-center mt-2">
                            <FaRegCalendarAlt className="text-gray-500 mr-2" />
                            {job.date}
                        </div>
                    </div>
                    <div className="text-sm text-gray-600 mb-3">
                        <div className="flex items-center mt-2">
                            <FaMapMarkerAlt className="text-gray-500 mr-2" />
                            {job.city}
                        </div>
                    </div>
                </div>
                <a href={job.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block fill-available w-full text-center font-bold p-2 rounded-full transition duration-150 ease-in-out no-underline"
                    style={{ backgroundColor, color: '#fff' }}
                >
                    {intl.formatMessage({ id: "careerPageWidget.discoverMore", defaultMessage: "Discover More" })}
                </a>
            </div>
        </div>
    );

    const renderTable = () => (
        <div className="overflow-x-auto">
            <table className="w-full bg-white shadow-lg text-left" style={{ borderCollapse: 'separate', borderSpacing: 0, border: '1px solid #e5e7eb', borderRadius: borderRadius }}>
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase font-bold tracking-wider border-b border-r" style={{ borderTopLeftRadius: borderRadius }}>
                            {intl.formatMessage({ id: "careerPageWidget.title", defaultMessage: "Title" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase font-bold tracking-wider border-b border-r">
                            {intl.formatMessage({ id: "careerPageWidget.date", defaultMessage: "Date" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase font-bold tracking-wider border-b border-r">
                            {intl.formatMessage({ id: "careerPageWidget.location", defaultMessage: "Location" })}
                        </th>
                        <th className="px-6 py-3 text-left text-xs text-gray-500 uppercase font-bold tracking-wider border-b" style={{ borderTopRightRadius: borderRadius }}>
                            {intl.formatMessage({ id: "careerPageWidget.details", defaultMessage: "Details" })}
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white">
                    {jobs.map((job, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? `${backgroundColor}22` : white }}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-b border-r">
                                {job.title}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-r">
                                <div className="flex items-center">
                                    <FaRegCalendarAlt className="text-gray-500 mr-2" />
                                    {job.date}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-b border-r">
                                <div className="flex items-center">
                                    <FaMapMarkerAlt className="text-gray-500 mr-2" />
                                    {job.city}
                                </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium border-b">
                                <a href={job.link} target="_blank" rel="noopener noreferrer" className="p-2 px-4 no-underline" style={{ backgroundColor, color: '#fff', borderRadius }}>
                                    {intl.formatMessage({ id: "careerPageWidget.discoverMore", defaultMessage: "Discover More" })}
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="p-4">
            {jobs.length === 0 ? (
                <p className="text-center text-gray-500 text-lg mt-4">{intl.formatMessage({ id: "careerPageWidget.noOpenPositions", defaultMessage: "No open positions available." })}</p>
            ) : (
                displayMode === 'table' ? (
                    renderTable()
                ) : (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
                        {jobs.map(renderCard)}
                    </div>
                )
            )}
            {hasMoreJobs && jobs.length > 0 && (
                <div className="mt-6">
                    <button
                        onClick={loadMore}
                        className="cursor-pointer px-6 text-center font-bold p-2 rounded-full transition duration-150 ease-in-out no-underline"
                    >
                        {intl.formatMessage({ id: "careerPageWidget.discoverMore", defaultMessage: "Load More" })}
                    </button>
                </div>
            )}
        </div>
    );
};

export default JobWidget;

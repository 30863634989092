"use client";
import React from "react";
import {
    motion,
    useScroll,
    useTransform,
    useSpring,
    MotionValue,
} from "framer-motion";
import PrimaryButton from "./primary-button";
import { FormattedMessage, useIntl } from "react-intl";

export const Hero = ({
    products,
}: {
    products: {
        title: string;
        link: string;
        thumbnail: string;
    }[];
}) => {
    const firstRow = products.slice(0, 5);
    const secondRow = products.slice(5, 10);
    const thirdRow = products.slice(10, 15);
    const ref = React.useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start start", "end start"],
    });

    const springConfig = { stiffness: 300, damping: 30, bounce: 100 };

    const translateX = useSpring(
        useTransform(scrollYProgress, [0, 1], [0, 1000]),
        springConfig
    );
    const translateXReverse = useSpring(
        useTransform(scrollYProgress, [0, 1], [0, -1000]),
        springConfig
    );
    const rotateX = useSpring(
        useTransform(scrollYProgress, [0, 0.2], [15, 0]),
        springConfig
    );
    const opacity = useSpring(
        useTransform(scrollYProgress, [0, 0.2], [0.2, 1]),
        springConfig
    );
    const rotateZ = useSpring(
        useTransform(scrollYProgress, [0, 0.2], [20, 0]),
        springConfig
    );
    const translateY = useSpring(
        useTransform(scrollYProgress, [0, 0.2], [-500, 100]),
        springConfig
    );
    return (
        // <div className="h-[50rem] w-full dark:bg-black bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center">
        //     <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-black bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]">

        <div
            ref={ref}
            className="pb-16 overflow-hidden antialiased relative  bg-white bg-dot-royalblue/[0.2] flex flex-col self-auto [perspective:1000px] [transform-style:preserve-3d]"
        >
            <Header />
            <motion.div
                style={{
                    rotateX,
                    rotateZ,
                    translateY,
                    opacity,
                }}
                className=""
            >
                <motion.div className="flex flex-row-reverse space-x-reverse space-x-20 mb-20">
                    {firstRow.map((product) => (
                        <ProductCard
                            product={product}
                            translate={translateX}
                            key={product.title}
                        />
                    ))}
                </motion.div>
                <motion.div className="flex flex-row mb-20 space-x-20 ">
                    {secondRow.map((product) => (
                        <ProductCard
                            product={product}
                            translate={translateXReverse}
                            key={product.title}
                        />
                    ))}
                </motion.div>
                <motion.div className="flex flex-row-reverse space-x-reverse space-x-20">
                    {thirdRow.map((product) => (
                        <ProductCard
                            product={product}
                            translate={translateX}
                            key={product.title}
                        />
                    ))}
                </motion.div>
            </motion.div>
        </div>

        // </div>
    );
};

export const Header = () => {
    const intl = useIntl();
    const buttonText = intl.formatMessage({ id: 'hero.cta', defaultMessage: 'Request a demo' });

    return (
        <div className="flex-1 max-w-full text-center text-33xl z-[200]">
            <div className="h-full flex flex-col px-4 md:px-10 justify-center pt-[14vh] md:pt-40">
                <div className="flex flex-col lg:flex-row lg:gap-24 items-center justify-center">
                    <div className="w-full flex flex-col gap-6 items-center justify-center lg:max-w-[70vw]">
                        <h1 className="m-0 text-[40px] md:text-[56px] lg:text-[60px]">
                            <FormattedMessage
                                id="hero.title"
                                defaultMessage="Give a <b>BOOST</b> to your hiring"
                                values={{ b: (chunks) => <b className="text-royalblue">{chunks}</b> }}
                            />
                        </h1>
                        <div className="text-lg font-medium">
                            <FormattedMessage
                                id="hero.description"
                                defaultMessage="Matchtal revolutionizes recruitment with <b>artificial intelligence</b>, ensuring precise and fast candidate screening through an easy-to-use platform. Simplify your hiring, and find the ideal candidate. Explore how Matchtal can improve your selection process."
                                values={{ b: (chunks) => <b>{chunks}</b> }}
                            />
                        </div>
                        <a href="#contacts" className="pt-4 lg:pt-0 cursor-pointer font-bold text-current no-underline hover:no-underline focus:no-underline hover:text-royalblue">
                            <PrimaryButton
                                lineRoundedSearch="/line-roundedsearch.svg"
                                icon="/line-roundedarrow-right.svg"
                                buttonText={buttonText}
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ProductCard = ({
    product,
    translate,
}: {
    product: {
        title: string;
        link: string;
        thumbnail: string;
    };
    translate: MotionValue<number>;
}) => {
    return (
        <motion.div
            style={{
                x: translate,
            }}
            whileHover={{
                y: -20,
            }}
            key={product.title}
            className="group/product h-96 w-[30rem] relative flex-shrink-0"
        >
            <a
                href={product.link}
                className="block group-hover/product:shadow-2xl "
            >
                <img
                    src={product.thumbnail}
                    className="object-fill object-left-top absolute w-[500px] inset-0"
                    alt={product.title}
                />
            </a>
            <div className="absolute inset-0 h-full w-full opacity-0  pointer-events-none"></div>
            {/* <h2 className="absolute bottom-4 left-4 opacity-0 group-hover/product:opacity-100 text-white">
                {product.title}
            </h2> */}
        </motion.div>
    );
};

import React from 'react';
import PrimaryButton from './primary-button';
import { FormattedMessage, useIntl } from 'react-intl';

const MoreFeatures: React.FC = () => {
    const intl = useIntl();

    return (
        <section className="relative w-full bg-neutral-colors-white flex flex-col lg:items-start lg:justify-start pt-[100px] lg:pt-[107px] lg:pr-[100px] lg:pl-[200px] pb-[62px] gap-[23px] box-border overflow-x-hidden">
            <img className="hidden lg:block absolute top-[180px] right-0 w-[10vw] md:w-[18vw]" src="assets/horizontal-arrow.svg"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000" />
            <img className="hidden lg:block absolute top-[250px] left-0 w-[10vw]" src="assets/down-arrow.svg"
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000" />
            <img className="lg:hidden absolute w-[16vw] md:w-auto top-[6px] lg:top-[250px] right-16 lg:right-0 lg:left-0" src="assets/down-arrow-left.svg" />
            <div className="flex flex-col lg:flex-row items-start p-4 lg:p-0 justify-between max-w-full lg:gap-10 text-right text-33xl">
                <div className="flex flex-col">
                    <img
                        className="w-56 lg:w-[200px] object-contain"
                        loading="lazy"
                        alt=""
                        src="/assets/data-charts-icon.svg"
                    />
                    <div className="mt-4 lg:w-[30vw] text-3xl text-left lg:text-lg">
                        <ul className="pl-[29px]">
                            <li><FormattedMessage id="moreFeatures.analytics" defaultMessage="Analyze candidate performances and relevance in interviews." /></li>
                            <li><FormattedMessage id="moreFeatures.insights" defaultMessage="Gain insights on your hiring pipeline." /></li>
                            <li><FormattedMessage id="moreFeatures.heatmap" defaultMessage="Track a heatmap of performance for each branch." /></li>
                            <li><FormattedMessage id="moreFeatures.monitorKPIs" defaultMessage="Monitor critical KPIs for your business on dedicated dashboards." /></li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col lg:w-[40vw] box-border lg:pt-[31px]">
                    <div className="flex flex-col items-start justify-start lg:gap-[36px_143px]">
                        <h2 className="text-left font-bold inline-block text-[36px] lg:text-[60px]">
                            <FormattedMessage id="moreFeatures.more" defaultMessage="...AND MUCH MORE" />
                        </h2>
                        <div className="text-3xl text-left lg:text-lg">
                            <ul className="pl-[29px]">
                                <li><FormattedMessage id="moreFeatures.quickAccess" defaultMessage="Fast and secure access with Microsoft and Google." /></li>
                                <li><FormattedMessage id="moreFeatures.platformAvailability" defaultMessage="Web platform, also available for mobile and desktop." /></li>
                                <li><FormattedMessage id="moreFeatures.integration" defaultMessage="We can integrate with your current ATS, CMS, and Management systems." /></li>
                                <li><FormattedMessage id="moreFeatures.support" defaultMessage="Customized assistance and training." /></li>
                                <li><FormattedMessage id="moreFeatures.customRequests" defaultMessage="Request customizations or adaptations for your specific needs." /></li>
                            </ul>
                        </div>
                    </div>
                    <a href="#contacts" className="p-4 w-fit cursor-pointer text-current no-underline hover:no-underline focus:no-underline hover:text-royalblue relative leading-[18px] inline-block min-w-[69px] shrink-0">
                        <PrimaryButton
                            lineRoundedSearch="/line-roundedsearch.svg"
                            icon="/line-roundedarrow-right.svg"
                            buttonText={intl.formatMessage({ id: "moreFeatures.button.requestDemo", defaultMessage: "Request a demo" })}
                        />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default MoreFeatures;

import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

const CandidateArchive: React.FC = () => {
    const rotatingImageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;

            // Adjust the rotation speed and direction here
            const rotationDegrees = scrollPosition * 0.11; // This controls the speed of the rotation

            if (rotatingImageRef.current) {
                rotatingImageRef.current.style.transform = `rotate(${rotationDegrees}deg)`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <section className="relative w-full bg-floralwhite flex flex-col items-start justify-start py-16 xl:py-32 px-6 gap-[24px_96px] xl:gap-[48px_96px] xl:gap-[96px] box-border text-33xl text-left">
            <div className="flex flex-col xl:flex-row items-start justify-start max-w-full w-full py-0 xl:px-[49px] xl:px-6 box-border">
                <div className="flex-1 relative max-w-full xl:h-auto">
                    <div className="flex flex-col xl:flex-row items-start justify-between max-w-full gap-5 text-right xl:flex-wrap">
                        <div className="flex flex-col text-start items-start justify-start gap-[18px_70.4px] max-w-full xl:gap-[35px_70.4px] xl:min-w-full xl:flex-1">
                            <div>
                                <div className="w-[62px] h-2.5 bg-royalblue" />
                                <FormattedMessage id="candidateArchive.title" defaultMessage="AI CANDIDATE ARCHIVE" />
                            </div>
                            <div className="xl:w-[330px] text-3xl max-w-full text-left">
                                <ul className="pl-[29px]">
                                    <li><FormattedMessage id="candidateArchive.saveCandidates" defaultMessage="Save candidates in the database." /></li>
                                    <li><FormattedMessage id="candidateArchive.searchSemantic" defaultMessage="Search by semantic meaning to find the best candidate in your database." /></li>
                                    <li><FormattedMessage id="candidateArchive.reassociateCandidates" defaultMessage="Re-associate unmatched candidates with every new open position." /></li>
                                    <li><FormattedMessage id="candidateArchive.keepCVsUpdated" defaultMessage="Keep your candidates' CVs updated with mail automation to stay GDPR compliant." /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col items-start justify-center xl:justify-start pt-0.5 box-border xl:max-w-full text-left text-gray xl:flex-1">
                            <img
                                ref={rotatingImageRef}
                                className="object-cover hidden xl:block absolute top-0 right-0"
                                loading="lazy"
                                alt=""
                                src="assets/archive-icon-and-round-text.svg"
                            />
                            <img
                                className="w-[90vw] xl:w-[50vw] xl:absolute top-[200px] right-36"
                                loading="lazy"
                                alt=""
                                src="assets/archive-screen.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CandidateArchive;


import React, { useState, useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { black } from 'tailwindcss/colors';
import AddressAutocomplete from './address-autocomplete';

type CandidateRequestModel = {
    name: string;
    familyName: string;
    contact: string;
    // cvCoverName: string;
    // cvCoverInBase64: string;
    cvFileName: string;
    cvInBase64: string;
    city: string;
    state: string,
    province: string,
    address: string,
    fullAddress: string;
    postalCode: string;
    telephoneNumber: string;
    birthDate: string;
    studyLevel: string;
    jobTitle: string;
    yearsOfExperience: string;
    currentPosition: string;
    currentWorkingArea: string;
};

// type CandidateSupplierRequestModel = {
//     id: string;
//     username: string;
//     password: string;
// };

type JobApplicationRequestModel = {
    creationDateTime: string;
    jobRef: string;
    jobTitle: string;
    boardCode: string;
    // candidateSupplier: CandidateSupplierRequestModel;
    candidate: CandidateRequestModel;
};

interface JobApplicationProps {
    jobTitle: string;
    color: string;
    radius: string;
    privacyPolicyLinks: string[];
    positionId: string;
    companyId: string;
    jobId: string;
    boardCode: string;
}

enum EducationLevel {
    CAP_BEP = "CAP_BEP",
    BAC = "BAC",
    BAC2 = "BAC2",
    BAC3 = "BAC3",
    BAC4 = "BAC4",
    BAC5 = "BAC5",
    BAC6 = "BAC6",
    PHD = "PHD",
    POSTDOC = "POSTDOC",
}

const JobApplication: React.FC<JobApplicationProps> = ({
    jobTitle,
    privacyPolicyLinks,
    companyId: companyId,
    positionId: positionId,
    jobId: jobId,
    radius: radius,
    color: color,
    boardCode: boardCode }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customerId = params.get('customerId') || '';
    const intl = useIntl();

    const [name, setName] = useState('');
    const [familyName, setFamilyName] = useState('');
    const [resumeFile, setResumeFile] = useState<File | null>(null);
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [resume, setResume] = useState<string | ArrayBuffer | null>(null);
    const [thankYouMessage, setThankYouMessage] = useState('');
    const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
    const [privacyConsent, setPrivacyConsent] = useState(false);

    const [fullAddress, setFullAddress] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [province, setProvince] = useState<string>('');
    const [address, setAddress] = useState<string>('');

    const [birthDate, setBirthDate] = useState('');
    const [studyLevel, setStudyLevel] = useState<EducationLevel | "">("");
    const [currentPosition, setCurrentPosition] = useState('');
    const [yearsOfExperience, setYearsOfExperience] = useState('');
    const [currentWorkingArea, setCurrentWorkingArea] = useState('');
    const [validationError, setValidationError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleAddressSelection = (address: {
        fullAddress: string;
        state: string;
        postalCode: string;
        city: string;
        province: string;
        address: string;
    }) => {
        // Set all the variables based on the selection
        setFullAddress(address.fullAddress);
        setState(address.state);
        setPostalCode(address.postalCode);
        setCity(address.city);
        setProvince(address.province);
        setAddress(address.address);
    };


    const getDisplayName = (level: EducationLevel): string => {
        switch (level) {
            case EducationLevel.CAP_BEP:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.CAP_BEP", defaultMessage: "Attestazione / Diploma Professionale" });
            case EducationLevel.BAC:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC", defaultMessage: "Diploma di Maturità" });
            case EducationLevel.BAC2:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC2", defaultMessage: "Laurea Breve" });
            case EducationLevel.BAC3:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC3", defaultMessage: "Laurea Triennale" });
            case EducationLevel.BAC4:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC4", defaultMessage: "Laurea Specialistica / a ciclo unico" });
            case EducationLevel.BAC5:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC5", defaultMessage: "Laurea Magistrale" });
            case EducationLevel.BAC6:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.BAC6", defaultMessage: "Master 2° livello / Magistrale + specializzazione" });
            case EducationLevel.PHD:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.PHD", defaultMessage: "Dottorato" });
            case EducationLevel.POSTDOC:
                return intl.formatMessage({ id: "careerPageApply.selectStudyLevel.POSTDOC", defaultMessage: "Ricerca post dottorato" });
            default:
                return "";
        }
    };

    const handleStudyLevelSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as EducationLevel; // Cast to enum type
        setStudyLevel(value);
    };

    const handleFile = (file: File) => {
        const acceptedTypes = [
            'application/pdf',
            'application/vnd.oasis.opendocument.text',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'image/png',
            'image/jpeg'
        ];

        if (acceptedTypes.includes(file.type)) {
            if (file.size > 5 * 1024 * 1024) { // 5MB limit
                alert(intl.formatMessage({ id: "careerPageApply.fileSize", defaultMessage: "File size must not exceed 5MB." }));
                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const base64String = (reader.result as string).split(',')[1];
                setResume(base64String);
                setResumeFile(file);
            };
            reader.readAsDataURL(file);
        } else {
            alert(intl.formatMessage({ id: "careerPageApply.uploadFile", defaultMessage: "Please upload a valid file (PDF, ODT, DOC, DOCX, PNG, JPG)." }));
        }
    };

    const handleFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file) {
            handleFile(file);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file) {
            handleFile(file);
        }
    };

    const errorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (validationError && errorRef.current) {
            errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [validationError]);

    const validateForm = (): string | null => {
        if (!name.trim()) return intl.formatMessage({ id: "careerPageApply.validation.name", defaultMessage: "Full Name is required" });
        if (!familyName.trim()) return intl.formatMessage({ id: "careerPageApply.validation.familyName", defaultMessage: "Surname is required" });
        if (!email.trim()) return intl.formatMessage({ id: "careerPageApply.validation.email", defaultMessage: "Email is required" });
        if (!/^\S+@\S+\.\S+$/.test(email)) return intl.formatMessage({ id: "careerPageApply.validation.emailFormat", defaultMessage: "Invalid email format" });
        if (!phone.trim()) return intl.formatMessage({ id: "careerPageApply.validation.phone", defaultMessage: "Phone is required" });
        if (!/^\+?[\d\s-]{7,}$/.test(phone)) return intl.formatMessage({ id: "careerPageApply.validation.phoneFormat", defaultMessage: "Invalid phone format" });
        if (!resumeFile) return intl.formatMessage({ id: "careerPageApply.validation.resume", defaultMessage: "Resume is required" });
        if (resumeFile && resumeFile.size > 5 * 1024 * 1024) return intl.formatMessage({ id: "careerPageApply.fileSize", defaultMessage: "File size must not exceed 5MB." });
        if (!privacyConsent) return intl.formatMessage({ id: "careerPageApply.validation.privacyConsent", defaultMessage: "Please agree to the privacy policy" });

        // Add more validations for additional fields if needed
        return null;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const validationError = validateForm();
        if (validationError) {
            setValidationError(validationError);
            return;
        }

        setValidationError(null);
        setLoading(true);

        const creationDateTime = new Date().toISOString();
        // const candidateSupplier: CandidateSupplierRequestModel = {
        //     id: customerId,
        //     username: "",
        //     password: ""
        // };

        const candidate: CandidateRequestModel = {
            name: name.split(' ')[0],
            familyName: familyName,
            contact: email,
            cvFileName: resumeFile ? resumeFile.name : "",
            cvInBase64: resume ? resume.toString() : "",
            telephoneNumber: phone,
            // cvCoverName: "",
            // cvCoverInBase64: "",
            city: city,
            state: state,
            province: province,
            address: address,
            fullAddress: fullAddress,
            postalCode: postalCode,
            birthDate: birthDate,
            yearsOfExperience: yearsOfExperience,
            studyLevel: studyLevel,
            jobTitle: jobTitle,
            currentPosition: currentPosition,
            currentWorkingArea: currentWorkingArea,
        };

        // const apiUrl = `https://app.matchtal.com/getJobPostById`;
        const apiUrl = process.env.REACT_APP_API_URL + "/postCandidateApplicants";
        const apiKey = process.env.REACT_APP_GET_JOBPOSTS_SERVER_API_KEY;

        const jobApplicationRequest: JobApplicationRequestModel = {
            jobRef: companyId + "__" + positionId + "__" + jobId,
            jobTitle: jobTitle,
            creationDateTime: creationDateTime,
            // candidateSupplier: candidateSupplier,
            boardCode: boardCode,
            candidate: candidate
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify(jobApplicationRequest)
            });

            if (response.ok) {
                setThankYouMessage(intl.formatMessage({ id: "careerPageApply.successMessage", defaultMessage: "Application submitted successfully!" }));
            } else {
                alert(intl.formatMessage({ id: "careerPageApply.errorMessage", defaultMessage: "Failed to submit application. Please try again later.'" }));
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('Failed to submit application. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="fill-available max-w-lg bg-white p-8 rounded-lg shadow-md"
                style={{
                    borderRadius: radius
                }}>
                <h2 className="text-2xl font-bold mb-6">{intl.formatMessage({ id: "careerPageApply.applyForJob", defaultMessage: "Apply for Job" })}</h2>
                {thankYouMessage ? (
                    <div className="text-green-500">{thankYouMessage}</div>
                ) : (
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.firstName", defaultMessage: "First Name:" })}</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                disabled={loading}
                            />
                            {validationError && !name.trim() && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "careerPageApply.validation.name", defaultMessage: "First Name is required" })}
                                </p>
                            )}
                        </div>
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.surname", defaultMessage: "Surname:" })}</label>
                            <input
                                type="text"
                                value={familyName}
                                onChange={(e) => setFamilyName(e.target.value)}
                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                disabled={loading}
                            />
                            {validationError && !familyName.trim() && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "careerPageApply.validation.surname", defaultMessage: "Surname is required" })}
                                </p>
                            )}
                        </div>
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.email", defaultMessage: "Email:" })}</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                disabled={loading}
                            />
                            {validationError && !email.trim() && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "careerPageApply.validation.email", defaultMessage: "Email is required" })}
                                </p>
                            )}
                            {validationError && email.trim() && !/^\S+@\S+\.\S+$/.test(email) && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "careerPageApply.validation.emailFormat", defaultMessage: "Invalid email format" })}
                                </p>
                            )}

                        </div>
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.phoneNumber", defaultMessage: "Phone:" })}</label>
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                required
                                disabled={loading}
                            />
                            {validationError && !phone.trim() && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "careerPageApply.validation.phone", defaultMessage: "Phone is required" })}
                                </p>
                            )}
                            {validationError && phone.trim() && !/^\+?[\d\s-]{7,}$/.test(phone) && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "careerPageApply.validation.phoneFormat", defaultMessage: "Invalid phone format" })}
                                </p>
                            )}
                        </div>
                        <div>
                            <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.uploadResume", defaultMessage: "Upload Resume (PDF):" })}</label>
                            <div
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={handleFileDrop}
                                className="text-center fill-available py-8 border-dashed border-2 border-slate-300 rounded-lg flex justify-center items-center cursor-pointer"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                {resumeFile ? (
                                    <span className='text-green-500'>{resumeFile.name}</span>
                                ) : (
                                    <span>{intl.formatMessage({ id: "careerPageApply.dragAndDropCv", defaultMessage: "Drop your resume or click here" })}</span>
                                )}
                            </div>
                            <input
                                type="file"
                                accept="application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpeg"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                className="hidden"
                                disabled={loading}
                            />
                            {validationError && !resumeFile && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "careerPageApply.validation.resume", defaultMessage: "Resume is required" })}
                                </p>
                            )}
                        </div>
                        {/* <div>
                            <label className="block text-left mb-2 text-gray-700">Cover Letter:</label>
                            <textarea
                                value={coverLetter}
                                onChange={(e) => setCoverLetter(e.target.value)}
                                rows={5}
                                className="fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div> */}
                        <div className="flex justify-start">
                            <label className="switch">
                                <input
                                    type="checkbox"
                                    checked={showAdditionalInfo}
                                    onChange={() => setShowAdditionalInfo(!showAdditionalInfo)}
                                />
                                <span className="slider round"></span>
                            </label>
                            <span className="text-gray-700">{intl.formatMessage({ id: "careerPageApply.addMoreInformation", defaultMessage: "Add more information" })}</span>
                        </div>
                        {showAdditionalInfo && (
                            <>
                                <AddressAutocomplete onSelectAddress={handleAddressSelection} setLoading={setLoading}
                                    loading={loading} />

                                <div>
                                    <label className="block text-left mb-2 text-gray-700">
                                        {intl.formatMessage({ id: "careerPageApply.birthDate", defaultMessage: "Birth Date:" })}
                                    </label>
                                    <input
                                        type="date"
                                        value={birthDate}
                                        onChange={(e) => setBirthDate(e.target.value)}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">
                                        {intl.formatMessage({ id: "careerPageApply.studyLevel", defaultMessage: "Study Level:" })}
                                    </label>
                                    <select
                                        value={studyLevel}
                                        onChange={handleStudyLevelSelectChange}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none" // Add appearance-none here
                                        disabled={loading}
                                    >
                                        {Object.values(EducationLevel).map((level) => (
                                            <option key={level} value={level}>
                                                {getDisplayName(level)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.currentPosition", defaultMessage: "Current Position:" })}</label>
                                    <input
                                        type="text"
                                        value={currentPosition}
                                        onChange={(e) => setCurrentPosition(e.target.value)}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>
                                <div>
                                    <label className="block text-left mb-2 text-gray-700">
                                        {intl.formatMessage({ id: "careerPageApply.yearsOfExperience", defaultMessage: "Years Of Experience:" })}
                                    </label>
                                    <input
                                        type="number"
                                        value={yearsOfExperience}
                                        onChange={(e) => setYearsOfExperience(e.target.value)}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                        min="0" // Optional: Set minimum value to 0
                                        step="1" // Optional: Set increment/decrement step to 1
                                    />
                                </div>

                                <div>
                                    <label className="block text-left mb-2 text-gray-700">{intl.formatMessage({ id: "careerPageApply.currentWorkingArea", defaultMessage: "Current Working Area:" })}</label>
                                    <input
                                        type="text"
                                        value={currentWorkingArea}
                                        onChange={(e) => setCurrentWorkingArea(e.target.value)}
                                        className="text-left fill-available p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        disabled={loading}
                                    />
                                </div>

                            </>
                        )}
                        <div>
                            <div className="flex justify-start mb-4">
                                <input
                                    type="checkbox"
                                    id="privacyConsent"
                                    className="mr-2"
                                    checked={privacyConsent}
                                    onChange={(e) => {
                                        setPrivacyConsent(e.target.checked);
                                        if (e.target.checked) {
                                            setValidationError(null);
                                        }
                                    }}
                                />
                                <label htmlFor="privacyConsent" className="text-gray-700 text-left">
                                    {intl.formatMessage({
                                        id: "careerPageApply.privacyConsent",
                                        defaultMessage: "I agree to the privacy policy"
                                    })}
                                </label>
                            </div>

                            <div className='flex justify-start flex-wrap'>
                                {privacyPolicyLinks.map((link, index) => (
                                    <React.Fragment key={index}>
                                        <a
                                            href={link.startsWith('http') ? link : `https://${link}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 underline"
                                        >
                                            {`Policy ${index + 1}`}
                                        </a>
                                        {index < privacyPolicyLinks.length - 1 && <span> - </span>}
                                    </React.Fragment>
                                ))}
                            </div>

                            {validationError && !privacyConsent && (
                                <p className="text-red-500 text-sm mt-1">
                                    {intl.formatMessage({ id: "careerPageApply.validation.privacyConsent", defaultMessage: "Please agree to the privacy policy" })}
                                </p>
                            )}
                        </div>
                        <div className="text-center mt-6">
                            <button
                                type="submit"
                                className="cursor-pointer bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 fill-available rounded-full transition duration-150 ease-in-out no-underline text-sm"
                                style={{
                                    backgroundColor: `#${color}`
                                }}
                                disabled={loading}
                            >
                                {loading ? intl.formatMessage({ id: "careerPageApply.loading", defaultMessage: "Submitting..." }) : intl.formatMessage({ id: "careerPageApply.submit", defaultMessage: "Submit" })}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default JobApplication;

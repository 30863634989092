import { FunctionComponent } from "react";
import PrimaryButton from "./primary-button";
import { useIntl } from "react-intl";

const Footer: FunctionComponent = () => {
  const intl = useIntl();
  const buttonText = intl.formatMessage({ id: 'footer.button.requestADemo', defaultMessage: 'Request a demo' });

  return (
    <footer className="flex flex-col items-center justify-center px-4 py-8 border-t border-solid border-gray-200">
      <div className="flex flex-col items-center gap-4 w-full lg:flex-row lg:justify-between lg:items-center lg:gap-10">
        <div className="bg-white flex flex-row items-center justify-start gap-12 box-border sticky max-w-full text-left text-lg px-4">
          <div className="flex flex-col items-center lg:items-start justify-start pt-1 px-0 pb-0">
            <img
              className="w-48 relative object-cover z-[5]"
              loading="lazy"
              alt=""
              src="assets/logo-complete.svg"
            />
            <div className="text-center lg:text-left text-[14px]">
              Via Coppalati,
              8 29122 Piacenza
              P.IVA e CF 01886400330
            </div>
          </div>
        </div>

        <span className="flex items-center gap-4 flex-row lg:gap-10">
          <ul className="max-w-[80vw] list-none flex flex-col items-center gap-4 w-full lg:flex-row lg:justify-around lg:gap-10 p-0">
            <li>
              <a className="text-gray" href="mailto:administration@spods.it">
                administration@spods.it
              </a>
            </li>
            <li>
              <a className="text-gray" href="mailto:spods@pec.it">
                spods@pec.it
              </a>
            </li>
            <li>
              <a className="text-gray" href="https://spods.it">
                https://spods.it
              </a>
            </li>
            <li>
              <a href="assets/Privacy Policy Web.pdf">
                Privacy Policy
              </a>
            </li>
            <li className="flex items-center gap-12">
              <a href="https://www.linkedin.com/company/79585674/" className="cursor-pointer text-current no-underline hover:no-underline focus:no-underline hover:text-royalblue relative leading-[18px] inline-block">
                <img className="w-12" src="assets/linkedin-logo.png" />
              </a>
              <a href="#contacts" className="cursor-pointer text-current no-underline hover:no-underline focus:no-underline hover:text-royalblue relative leading-[18px] inline-block">
                <PrimaryButton
                  lineRoundedSearch="/line-roundedsearch.svg"
                  icon="/line-roundedarrow-right.svg"
                  buttonText={buttonText}
                  padding="18px 21px"
                />
              </a>
            </li>
          </ul>
        </span>
      </div>
    </footer>
  );
};

export default Footer;

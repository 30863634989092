import { FunctionComponent, useState } from "react";
import PrimaryButton from "./primary-button";
import { Hamburger } from "./hamburger";
import { FormattedMessage, useIntl } from "react-intl";

const Header: FunctionComponent = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const intl = useIntl();

    return (
        <header className="border-b border-solid border-lightgray bg-white flex items-center justify-between px-4 py-3 lg:px-16 sticky top-0 z-50">
            <div className="flex items-center">
                <img
                    className="w-48 mr-2"
                    loading="lazy"
                    alt="pod logo"
                    src="assets/logo-complete.svg"
                />
            </div>
            <div className="lg:hidden">
                {/* <button className="" onClick={() => setIsMenuOpen(!isMenuOpen)}> */}
                {/* <svg data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"></path>
                    </svg> */}
                <Hamburger onClick={() => setIsMenuOpen(!isMenuOpen)} />
                {/* </button> */}
            </div>
            <div className={`absolute top-full left-0 w-full bg-white shadow-md py-5 px-4 ${isMenuOpen ? 'block' : 'hidden'} lg:block lg:static lg:shadow-none lg:p-0`}>
                <div className="flex flex-col lg:flex-row lg:justify-end items-center gap-4 text-center">
                    <a href="#advantages" className="text-gray hover:text-royalblue cursor-pointer text-current no-underline hover:no-underline focus:no-underline">
                        <FormattedMessage id="header.advantages" defaultMessage="Advantages" />
                    </a>
                    <a href="#features" className="text-gray hover:text-royalblue cursor-pointer text-current no-underline hover:no-underline focus:no-underline">
                        <FormattedMessage id="header.features" defaultMessage="Features" />
                    </a>
                    <a href="#contacts" className="text-gray hover:text-royalblue cursor-pointer text-current no-underline hover:no-underline focus:no-underline">
                        <FormattedMessage id="header.contacts" defaultMessage="Contacts" />
                    </a>
                    <a href="#faq" className="text-gray hover:text-royalblue cursor-pointer text-current no-underline hover:no-underline focus:no-underline">
                        <FormattedMessage id="header.faq" defaultMessage="FAQ" />
                    </a>
                    <a href="https://app.matchtal.com" className="pt-4 lg:pt-0 cursor-pointer text-current no-underline hover:no-underline focus:no-underline">
                        <PrimaryButton
                            lineRoundedSearch="/line-roundedsearch.svg"
                            icon="/line-roundedarrow-right.svg"
                            buttonText={intl.formatMessage({ id: "header.login", defaultMessage: "Login" })}
                            padding="14px 18px"
                            background="transparent"
                            isSecondary={true}
                        />
                    </a>
                    <a href="#contacts" className="pt-4 lg:pt-0 cursor-pointer text-current no-underline hover:no-underline focus:no-underline hover:text-royalblue">
                        <PrimaryButton
                            lineRoundedSearch="/line-roundedsearch.svg"
                            icon="/line-roundedarrow-right.svg"
                            buttonText={intl.formatMessage({ id: "header.requestDemo", defaultMessage: "Request a Demo" })}
                            padding="16px 18px"
                        />
                    </a>
                </div>

            </div>
        </header>
    );
}

export default Header;

import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

interface AdvantageCategory {
    title: string;
    points: string[];
}

const ValueProposition = () => {
    const intl = useIntl();
    const fatAsteriskRef = useRef<HTMLImageElement>(null);

    const advantageCategories = [
        {
            title: intl.formatMessage({ id: "valueProposition.advantages.title", defaultMessage: "THE ADVANTAGES" }),
            points: [
                intl.formatMessage({ id: "valueProposition.advantages.point1", defaultMessage: "Save precious time to dedicate to business-critical activities." }),
                intl.formatMessage({ id: "valueProposition.advantages.point2", defaultMessage: "Make more accurate choices in your candidate search (the most important asset)." }),
                intl.formatMessage({ id: "valueProposition.advantages.point3", defaultMessage: "Gain a competitive advantage by finding 'the best' first." }),
            ],
        },
        {
            title: intl.formatMessage({ id: "valueProposition.whyUs.title", defaultMessage: "WHY US?" }),
            points: [
                intl.formatMessage({ id: "valueProposition.whyUs.point1", defaultMessage: "Easy-to-use interface, for every resource." }),
                intl.formatMessage({ id: "valueProposition.whyUs.point2", defaultMessage: "Next-generation solution, truly powered by artificial intelligence." }),
                intl.formatMessage({ id: "valueProposition.whyUs.point3", defaultMessage: "Custom pricing, from small businesses to large enterprises, with a growth perspective." }),
                intl.formatMessage({ id: "valueProposition.whyUs.point4", defaultMessage: "GDPR 2024 Compliant." }),
            ],
        },
        {
            title: intl.formatMessage({ id: "valueProposition.isItForMe.title", defaultMessage: "IS IT FOR ME?" }),
            points: [
                intl.formatMessage({ id: "valueProposition.isItForMe.point1", defaultMessage: "If you are a staffing agency or a high-turnover company with large volumes of candidates and want to maximize allocation." }),
                intl.formatMessage({ id: "valueProposition.isItForMe.point2", defaultMessage: "If you are a company that needs to select the best talents, both for hard and soft skills." }),
                intl.formatMessage({ id: "valueProposition.isItForMe.point3", defaultMessage: "If you want to have a competitive advantage in terms of human resources without investing a fortune." }),
            ],
        },
    ];

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;

            if (fatAsteriskRef.current) {
                fatAsteriskRef.current.style.transform = `translateY(${scrollPosition * 0.1}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <section id="advantages" className="w-full h-auto relative flex flex-col lg:flex-row items-center lg:items-baseline justify-center py-16 lg:py-32 gap-12 ">
            <img
                className="w-[40vw] md:w-auto absolute top-[-80px] right-[-3vw]"
                src="assets/curl-arrow-down.svg"
                data-aos="fade-down-left"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
            />
            <img
                className="hidden md:block md:w-[40vw] lg:w-[30vw] absolute bottom-[-40px] md:bottom-[-60px] left-0 z-[4]"
                src="assets/curl-arrow-up.svg"
                data-aos="fade-up-right"
                data-aos-offset="200"
                data-aos-delay="50"
                data-aos-duration="1000"
            />
            <img ref={fatAsteriskRef} className="w-[20vw] md:w-auto absolute top-[-200px] left-[35vw] lg:left-[30vw]" src="assets/fat-asterisk.svg" />

            <div className="diagonal-bg absolute w-full h-full top-0 left-0 z-[-1]" />

            {advantageCategories.map((category, index) => (
                <div key={index} className="w-full lg:max-w-[25vw] bg-transparent flex flex-col items-start">
                    <h2 className="text-royalblue px-10 font-extrabold text-center mt-0 mb-4 text-[40px]">{category.title}</h2>
                    <ul className="list-none max-w-inherit px-10 lg:p-0 flex flex-col items-start">
                        {index === 1 && <img
                            src="/assets/draw-underline.svg"
                            className="w-[70vw] self-start md:w-auto"
                            alt="Underline"
                            data-aos="fade-right"
                            data-aos-offset="200"
                            data-aos-delay="50"
                            data-aos-duration="1000"
                        />
                        }
                        {category.points.map((point, idx) => (
                            <li key={idx} className="flex items-center gap-2 text-left my-4">
                                <img src="/assets/check.svg" alt="Check" className="w-6 h-6" />
                                <p className="font-semibold">{point}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </section>
    );
};


export default ValueProposition;

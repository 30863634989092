import React, { useState, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

// Define a type for the FAQ item
type FAQItem = {
    question: string;
    answer: string;
};

type AccordionItemProps = {
    faq: FAQItem;
};

const AccordionItem: FunctionComponent<AccordionItemProps> = ({ faq }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <div className="border m-2 p-2">
            <button
                className="cursor-pointer w-full text-left p-2 font-semibold text-3xl flex items-center gap-4 bg-transparent"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span
                    className={`transform transition-transform ${isOpen ? 'rotate-90' : 'rotate-0'}`}
                    style={{ display: 'inline-block', marginRight: '8px' }}
                >
                    <img src="assets/angle.svg" className="w-5" />
                </span>
                {faq.question}
            </button>
            {isOpen && <div className="p-2 text-gray text-left">{faq.answer}</div>}
        </div>
    );
};

const Faq: FunctionComponent = () => {
    const intl = useIntl();

    // FAQs data
    const faqs: FAQItem[] = [
        {
            question: intl.formatMessage({ id: "faq.question1" }),
            answer: intl.formatMessage({ id: "faq.answer1" })
        },
        {
            question: intl.formatMessage({ id: "faq.question2" }),
            answer: intl.formatMessage({ id: "faq.answer2" })
        },
        {
            question: intl.formatMessage({ id: "faq.question3" }),
            answer: intl.formatMessage({ id: "faq.answer3" })
        },
        {
            question: intl.formatMessage({ id: "faq.question4" }),
            answer: intl.formatMessage({ id: "faq.answer4" })
        },
        {
            question: intl.formatMessage({ id: "faq.question5" }),
            answer: intl.formatMessage({ id: "faq.answer5" })
        },
        {
            question: intl.formatMessage({ id: "faq.question6" }),
            answer: intl.formatMessage({ id: "faq.answer6" })
        },
        {
            question: intl.formatMessage({ id: "faq.question7" }),
            answer: intl.formatMessage({ id: "faq.answer7" })
        },
        {
            question: intl.formatMessage({ id: "faq.question8" }),
            answer: intl.formatMessage({ id: "faq.answer8" })
        },
    ];
    const halfwayIndex = Math.ceil(faqs.length / 2);
    const firstHalfFaqs = faqs.slice(0, halfwayIndex);
    const secondHalfFaqs = faqs.slice(halfwayIndex);

    return (
        <section id="faq" className="max-w-7xl mx-auto py-16 lg:py-32 px-4 lg:px-16">
            <h2 className="text-[36px] lg:text-[60px] font-bold mb-4 text-center pb-10">FREQUENTLY ASKED QUESTIONS</h2>
            <div className="flex flex-col md:flex-row flex-wrap justify-between">
                <div className="flex-1 flex flex-col justify-center min-w-0 lg:pl-[6%]">
                    {firstHalfFaqs.map((faq, index) => (
                        <AccordionItem key={index} faq={faq} />
                    ))}
                </div>
                <img src="assets/big-arrow-down.svg"
                    data-aos="fade-down"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="2000"
                    className="lg:w-6 hidden lg:block" />
                <div className="flex-1 flex flex-col justify-center min-w-0 lg:pl-[6%] ">
                    {secondHalfFaqs.map((faq, index) => (
                        <AccordionItem key={index} faq={faq} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Faq;

import { FunctionComponent, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

const JobPosition: FunctionComponent = () => {
    const positionScreenRef = useRef<HTMLImageElement>(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const screenWidth = window.innerWidth;
    //         const scrollPosition = window.pageYOffset;
    //         if (screenWidth > 1024) {
    //             if (positionScreenRef.current) {
    //                 positionScreenRef.current.style.transform = `translateY(${scrollPosition * 0.1}px)`;
    //             }
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);

    return (
        <section className="w-full bg-floralwhite flex flex-col py-16 relative min-h-[700px]">
            <div className="px-6 flex flex-col lg:pl-20">
                <div className="flex flex-col lg:flex-row">
                    <div className="flex flex-col lg:mr-[30vw] text-left mt-12 mb-8">
                        <div className="w-[62px] h-2.5 bg-royalblue" />
                        <h2 className="text-[36px] lg:text-[60px] font-bold m-0">
                            <FormattedMessage id="aiJobPositions.title" defaultMessage="AI JOB POSITIONS" />
                        </h2>
                        <div className="text-3xl max-w-xl">
                            <ul className="pl-7 list-disc flex flex-col gap-4 max-w-md">
                                <li>
                                    <FormattedMessage id="aiJobPositions.item1" defaultMessage="Generate a job position with AI in moments." />
                                </li>
                                <li>
                                    <FormattedMessage id="aiJobPositions.item2" defaultMessage="Collaborate in drafting the best ad to attract the perfect candidates." />
                                </li>
                                <li>
                                    <FormattedMessage id="aiJobPositions.item3" defaultMessage="Find the best candidate with the required qualifications." />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <img
                        ref={positionScreenRef}
                        className="lg:absolute top-[180px] right-12 rounded-full w-full max-w-xl xl:max-w-3xl mx-auto"
                        loading="lazy"
                        alt=""
                        src="/assets/positions-screen.png"
                    />
                </div>
            </div>
        </section>
    );
};

export default JobPosition;


import { useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

const FeatureMatching = () => {
    const smallAsteriskRef = useRef<HTMLImageElement>(null);
    const curlArrowRef = useRef<HTMLImageElement>(null);
    const matchImageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.pageYOffset;
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            // Define multipliers for mobile, tablet, and desktop
            const mobileMultiplier = 0.006; // Less intense for mobile
            const tabletMultiplier = 0.02; // Moderate intensity for tablets
            const desktopMultiplier = 0.03; // More intense for desktop

            // Adjust the multiplier based on the screen width
            let asteriskMultiplier;
            if (screenWidth < 768) {
                asteriskMultiplier = mobileMultiplier;
            } else if (screenWidth >= 768 && screenWidth < 1280) {
                asteriskMultiplier = tabletMultiplier;
            } else {
                asteriskMultiplier = desktopMultiplier;
            }

            if (smallAsteriskRef.current) {
                smallAsteriskRef.current.style.transform = `translateY(${scrollPosition * asteriskMultiplier}px)`;
            }

            // Define a range for checking resolution
            // const isAround1180x820 = screenWidth >= 1170 && screenWidth <= 1190 && screenHeight >= 810 && screenHeight <= 830;

            // Adjusting multipliers as necessary for other elements, excluding matchImageRef for 1180x820 resolution
            let commonMultiplier;
            if (screenWidth < 768) {
                commonMultiplier = 0.01; // Less intense for mobile
            } else if (screenWidth >= 1280) {
                commonMultiplier = 0.1; // More intense for desktop
            } else {
                return; // Skipping other resolutions for commonMultiplier adjustment
            }

            if (curlArrowRef.current) {
                curlArrowRef.current.style.transform = `translateY(${scrollPosition * commonMultiplier}px)`;
            }

            // // Apply transformation to matchImageRef only if not around the 1180x820 resolution
            // if (!isAround1180x820 && matchImageRef.current) {
            //     matchImageRef.current.style.transform = `translateY(${scrollPosition * commonMultiplier}px)`;
            // }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <section id="features" className="w-full bg-white flex flex-col-reverse xl:flex-row min-h-[700px] xl:items-start xl:justify-between pt-16 xl:pt-32 relative">
            <div className="relative">
                <img
                    ref={matchImageRef}
                    className="xl:absolute top-[-50px] left-[-29px] w-[100vw] xl:w-[800px] xl:z-[10]"
                    alt=""
                    src="assets/matching-screen.png"
                />
            </div>
            <div className="flex flex-col items-start justify-start box-border p-6 sm:pb-0">
                <div className="flex flex-col items-start justify-start xl:pt-0 xl:px-12 ">
                    <div className="flex flex-row items-start justify-start pl-[15px]">
                        <div className="flex-1 flex flex-col items-start justify-start">
                            <div className="w-[62px] h-2.5 bg-royalblue" />
                            <h2 className="text-[36px] md:text-[60px] text-left font-bold m-0">
                                <FormattedMessage id="aiJobMatching.title" defaultMessage="AI JOB MATCHING" />
                            </h2>
                        </div>
                    </div>
                    <div className="text-left text-3xl pl-[15px] md:max-w-[40vw]">
                        <ul className="flex flex-col gap-4">
                            <li>
                                <FormattedMessage id="aiJobMatching.point1" defaultMessage="Match hundreds of candidates in moments to the best positions." />
                            </li>
                            <li>
                                <FormattedMessage id="aiJobMatching.point2" defaultMessage="Get evaluations on hard and soft skills." />
                            </li>
                            <li>
                                <FormattedMessage id="aiJobMatching.point3" defaultMessage="Select the best prospects to bring to interview." />
                            </li>
                        </ul>
                        <img
                            ref={smallAsteriskRef}
                            className="relative z-6 md:ml-[5vw] md:mt-[50px]"
                            alt=""
                            src="assets/small-asterisk.svg"
                        />
                        <img
                            ref={curlArrowRef}
                            className="absolute z-[10] bottom-[-120px] xl:bottom-[200px] w-24 xl:w-[10vw] right-[10vw]"
                            alt=""
                            src="assets/curly-arrow.svg"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeatureMatching;


import { FunctionComponent, useEffect, useRef } from "react";
import { FormattedMessage } from "react-intl";

const CandidateInterview: FunctionComponent = () => {
    const interviewScreenRef = useRef<HTMLImageElement>(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const screenWidth = window.innerWidth;
    //         const scrollPosition = window.pageYOffset;
    //         if (screenWidth > 1024) {
    //             if (interviewScreenRef.current) {
    //                 interviewScreenRef.current.style.transform = `translateY(${scrollPosition * 0.1}px)`;
    //             }
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => window.removeEventListener('scroll', handleScroll);
    // }, []);

    return (
        <section className="w-full bg-neutral-colors-white flex flex-col pt-0 px-0 lg:pb-[115px]">
            <div className="max-w-full flex lg:items-start lg:justify-start py-16 lg:py-32 px-4 lg:px-16 text-right text-33xl">
                <div className="flex-1 flex flex-col w-full">
                    <div className="flex flex-col lg:flex-row mt-[-2.4px] lg:flex-wrap">
                        <div className="flex-1 flex flex-col text-left">
                            <div className="w-[62px] h-2.5 bg-royalblue" />
                            <FormattedMessage id="candidateInterview.heading" defaultMessage="AI CANDIDATE INTERVIEW" />
                            <div className="flex relative items-start">
                                <img ref={interviewScreenRef} className="lg:absolute z-[4] lg:w-[60vw] w-[90vw] lg:top-[100px] pt-10 lg:pt-0 left-0" loading="lazy" alt="" src="/assets/interview-screen.png" />
                            </div>
                        </div>
                        <div className="w-full lg:flex-1 pt-[32px] text-left text-3xl lg:max-w-[28vw]">
                            <ul className="flex flex-col gap-4">
                                <li><FormattedMessage id="candidateInterview.customInterviews" defaultMessage="Prepare custom interviews for candidates and positions with AI in seconds." /></li>
                                <li><FormattedMessage id="candidateInterview.questionsAndBriefs" defaultMessage="Get questions, answers, and briefs to be prepared for the most technical interviews." /></li>
                                <li><FormattedMessage id="candidateInterview.schedule" defaultMessage="Schedule the interview in the calendar and invite your colleagues." /></li>
                                <li><FormattedMessage id="candidateInterview.collaborate" defaultMessage="Collaborate in evaluating candidates with chat and real-time feedback." /></li>
                                <li><FormattedMessage id="candidateInterview.useTools" defaultMessage="Use your favorite tools, such as Google Meet and Microsoft Teams, to obtain feedback from video interviews, with Artificial Intelligence." /></li>
                                <li><FormattedMessage id="candidateInterview.exportReport" defaultMessage="Export the report of each candidate anonymously." /></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CandidateInterview;

import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

const Contacts: FunctionComponent = () => {
    return (
        <div className="lg:w-full flex flex-row items-start py-0 lg:px-16 xl:px-64 box-border text-left text-21xl">
            <div className="border-solid border-t-[4px] lg:border-l-[4px] lg:border-t-0 border-royalblue flex flex-row flex-wrap items-start gap-[41px]">
                <div className="flex-1  pt-6 lg:pl-6 lg:pt-0 px-4 flex flex-col items-start justify-start gap-[8px]">
                    <h3 className="m-0 pb-6 relative font-bold inline-block max-w-full text-xl">
                        <FormattedMessage id="contact.alternativeMethods" defaultMessage="Prefer to contact us in other ways?:" />
                    </h3>
                    <div className="self-stretch flex flex-row items-start justify-start py-0 pr-0 pl-[3px] box-border max-w-full">
                        <div className="flex-1 flex flex-col lg:flex-row items-start justify-between max-w-full gap-[20px]">
                            <div className="flex flex-col items-start justify-start pt-2 px-0 pb-0 box-border max-w-full">
                                <b className="relative inline-block mq750:text-2xl">
                                    <p className="m-0">
                                        <span className="">{`Email: `}</span>
                                        <a className="text-7xl text-royalblue" href="mailto:administration@spods.it">administration@spods.it</a>
                                    </p>
                                    <p className="break-words">
                                        <span className="">{`Linkedin: `}</span>
                                        <a className="text-7xl text-royalblue break-words" href="https://www.linkedin.com/company/79585674/">
                                            https://www.linkedin.com/company/79585674/
                                        </a>
                                    </p>
                                </b>
                            </div>
                            <b className="relative inline-block shrink-0 mq750:text-2xl">
                                {/* <p className="m-0">
                                    <span className="">{`Pec: `}</span>
                                    <a className="text-7xl text-royalblue" href="mailto:spodssrl@pec.it">spodssrl@pec.it</a>
                                </p> */}

                            </b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './components/reportWebVitals';
import App from './App';
import enMessages from './locales/en.json';
import itMessages from './locales/it.json';
import { IntlProvider } from 'react-intl';

type MessageDictionary = {
  [key: string]: string;
};

type LocaleMessages = {
  [locale: string]: MessageDictionary;
};


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const userLocale = navigator.language.split('-')[0];

const messages: LocaleMessages = {
  en: enMessages,
  it: itMessages,
};

const currentMessages = messages[userLocale] || messages.en;

root.render(
  <React.StrictMode>
    <IntlProvider locale={userLocale} messages={currentMessages}>
      <App />
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

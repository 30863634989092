import { useState, FunctionComponent } from 'react';
import PrimaryButton from './primary-button';
import { FormattedMessage, useIntl } from 'react-intl';

interface FormErrors {
    [key: string]: string;
}

const ContactForm: FunctionComponent = () => {
    const intl = useIntl();

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        phone: '',
        message: '',
        privacypolicy: false,
        // marketingConsent: false,
    });

    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [isSubmitted, setIsSumbitted] = useState<boolean>(false);

    const handleChange = (e: any) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const validateForm = () => {
        let errors: any = {};
        if (!formData.name) errors.name = 'Il nome è richiesto';
        if (!formData.email) {
            errors.email = 'L\'email è richiesta';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = 'Email is invalid';
        }
        if (!formData.company) errors.company = 'L\'azienda è richiesta';
        // if (!formData.phone) errors.phone = 'Il Telefono è richiesto';
        if (!formData.privacypolicy) errors.privacypolicy = 'Il privacy policy consent è richiesto';
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if (!validateForm()) return;
        try {
            const response = await fetch('https://us-central1-spods-srl.cloudfunctions.net/sendMailOverHTTP', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                // alert(intl.formatMessage({ id: 'message.success' }));
                setIsSumbitted(true);
            } else {
                alert(intl.formatMessage({ id: 'message.failure' }));
            }
        } catch (error) {
            let errorMessage = 'An unexpected error occurred';
            if (error instanceof Error) {
                errorMessage = error.message;
            }

            alert(intl.formatMessage({ id: 'message.error' }, { error: errorMessage }));
        }
    };

    const buttonText = intl.formatMessage({ id: 'form.button.submit', defaultMessage: 'Send Message' });

    return (
        <div className="flex flex-col items-start justify-start gap-[29px] w-full">
            <form onSubmit={handleSubmit} className="flex flex-col items-start justify-start gap-4 w-full">
                <h2 className="m-0 pb-10 relative text-[36px] md:text-[60px] font-bold font-inherit inline-block shrink-0 [debug_commit:f6aba90] mq750:text-12xl mq750:leading-[35px] mq1050:text-23xl mq1050:leading-[46px]">
                    <FormattedMessage id="form.contactUs" defaultMessage="CONTACT US" />
                </h2>
                <img
                    className="absolute w-36 md:w-[250px] md:left-0 right-[0px] object-contain top-[-80px] md:top-[-127px]"
                    loading="lazy"
                    alt=""
                    data-aos="fade-right"
                    data-aos-offset="500"
                    data-aos-delay="50"
                    data-aos-duration="2000"
                    src="assets/bicolor-asterisk.svg"
                />
                <div className="relative font-medium inline-block max-w-full">
                    <FormattedMessage id="form.label.name" defaultMessage="Name and Surname*" />
                </div>
                <input
                    name="name"
                    type="text"
                    className="text-lg p-4 self-stretch flex-1 relative bg-neutral-colors-white box-border max-w-full border-[3px] border-solid border-gray"
                    placeholder={intl.formatMessage({ id: "form.placeholder.name", defaultMessage: "Your Name and Surname" })}
                    value={formData.name}
                    required
                    onChange={handleChange}
                />
                {formErrors.name && <p className="text-red-500">{formErrors.name}</p>}

                <div className="relative font-medium inline-block max-w-full">
                    <FormattedMessage id="form.label.email" defaultMessage="Email*" />
                </div>
                <input
                    name="email"
                    type="email"
                    className="text-lg p-4 self-stretch flex-1 relative bg-neutral-colors-white box-border max-w-full border-[3px] border-solid border-gray"
                    placeholder={intl.formatMessage({ id: "form.placeholder.email", defaultMessage: "Your best email" })}
                    value={formData.email}
                    required
                    onChange={handleChange}
                />
                {formErrors.email && <p className="text-red-500">{formErrors.email}</p>}

                <div className="relative font-medium inline-block max-w-full">
                    <FormattedMessage id="form.label.companyName" defaultMessage="Company Name*" />
                </div>
                <input
                    name="company"
                    type="text"
                    className="text-lg p-4 self-stretch flex-1 relative bg-neutral-colors-white box-border max-w-full border-[3px] border-solid border-gray"
                    placeholder={intl.formatMessage({ id: "form.placeholder.companyName", defaultMessage: "Your Company" })}
                    value={formData.company}
                    required
                    onChange={handleChange}
                />
                {formErrors.company && <p className="text-red-500">{formErrors.company}</p>}

                <div className="relative font-medium inline-block max-w-full">
                    <FormattedMessage id="form.label.phone" defaultMessage="Phone" />
                </div>
                <input
                    name="phone"
                    type="tel"
                    className="text-lg p-4 self-stretch flex-1 relative bg-neutral-colors-white box-border max-w-full border-[3px] border-solid border-gray"
                    placeholder={intl.formatMessage({ id: "form.placeholder.phone", defaultMessage: "Your Phone Number" })}
                    value={formData.phone}
                    onChange={handleChange}
                />
                {/* {formErrors.phone && <p className="text-red-500">{formErrors.phone}</p>} */}
                <textarea
                    name="message"
                    className="text-lg p-4 self-stretchp-4 h-auto relative bg-neutral-colors-white box-border w-full min-h-[200px] border-[3px] border-solid border-gray overflow-hidden resize-y"
                    placeholder={intl.formatMessage({ id: "form.label.message", defaultMessage: "Write us a message..." })}
                    value={formData.message}
                    onChange={handleChange}
                ></textarea>

                <div className="flex flex-col items-start">
                    <div className="flex items-center">
                        <input
                            name="privacypolicy"
                            type="checkbox"
                            className="h-5 w-5"
                            checked={formData.privacypolicy}
                            onChange={handleChange}
                        />
                        <span className="ml-2 text-left">
                            <FormattedMessage id="form.label.privacyPolicy" defaultMessage="I agree to the data processing according to the Privacy Policy*" />
                        </span>
                    </div>
                    {formErrors.privacypolicy && <p className="text-red-500">{formErrors.privacypolicy}</p>}

                    {/* <div className="flex items-center">
                        <input
                            name="marketingConsent"
                            type="checkbox"
                            className="h-5 w-5"
                            checked={formData.marketingConsent}
                            onChange={handleChange}
                        />
                        <span className="ml-2 text-left">
                            <FormattedMessage id="form.label.marketingConsent" defaultMessage="I agree to data processing for marketing purposes" />
                        </span>
                    </div> */}
                </div>

                {!isSubmitted ? (
                    <PrimaryButton
                        lineRoundedSearch="/line-roundedsearch.svg"
                        buttonText={buttonText}
                        icon="/line-roundedarrow-right.svg"
                        isSubmit={true}
                    />
                ) : (
                    <div className="flex flex-col items-start justify-start pt-4 px-0 pb-0 box-border max-w-full">
                        <b className="relative text-left text-3xl text-green-600">
                            <FormattedMessage id="form.submission.success" defaultMessage="🎉 You will be contacted as soon as possible by our team 🎉" />
                        </b>
                    </div>
                )}
            </form >
            <img className="absolute bottom-[-100px] md:bottom-[-100px] right-0 md:right-[20vw] w-48"
                data-aos="fade-left"
                data-aos-offset="500"
                data-aos-delay="10"
                data-aos-duration="1000"
                src="assets/big-asterisk.svg" />
        </div >
    );
};

export default ContactForm;

import { FunctionComponent } from "react";
import ContactForm from "./contact-form";
import Contacts from "./contacts";

const ContactUs: FunctionComponent = () => {
    return (
        <section className="w-full relative bg-neutral-colors-white flex flex-col gap-16 md:gap-32 items-center justify-start py-16 md:py-32">
            <div id="contacts" className="self-stretch flex flex-row items-start justify-center py-0 px-4 md:px-24 xl:px-64 box-border max-w-full">
                <ContactForm />
            </div>
            <Contacts />
            {/* <CustomersCarousel /> */}
        </section>
    );
};

export default ContactUs;
import { FunctionComponent } from "react";

type TextDividerProps = {
    message: string;
    source: string;
    sourceUrl: string;
};

const TextDivider: FunctionComponent<TextDividerProps> = ({ message, source, sourceUrl }) => {
    return (
        <section className="bg-royalblue flex flex-col items-center justify-start px-4 py-24 md:p-32">
            <div className="relative text-floralwhite text-center inline-block max-w-full mq450:text-[24px] mq1050:text-[32px]">
                <p className="m-0">
                    <span className="font-medium">
                        <span className="text-[36px] md:text-[60px]">{message}</span>
                        <span className="text-33xl">{` `}</span>
                    </span>
                </p>
                <p className="m-0">
                    <i className="text-21xl leading-[58px] font-medium">{`fonte: `}</i>
                    <i className="text-21xl leading-[58px] font-medium">
                        <a href={sourceUrl} className="[text-decoration:underline]">{source}</a>
                    </i>
                </p>
            </div>
            <div className="bg-white mt-6 h-1 md:h-2 w-40 md:w-64 rounded-xl">
            </div>
        </section>
    );
};

export default TextDivider;

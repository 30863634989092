import { FunctionComponent, useMemo, useState, CSSProperties } from 'react';

export type PrimaryButtonType = {
    lineRoundedSearch?: string;
    buttonText?: string;
    icon?: string;
    propWidth?: CSSProperties['width'];
    propDisplay?: CSSProperties['display'];
    propMinWidth?: CSSProperties['minWidth'];
    background?: CSSProperties['background'];
    padding?: CSSProperties['padding'];
    color?: CSSProperties['color'];
    isSecondary?: boolean;
    isSubmit?: boolean;
};

const PrimaryButton: FunctionComponent<PrimaryButtonType> = ({
    lineRoundedSearch,
    buttonText,
    icon: lineRoundedArrowRight,
    propWidth,
    propDisplay,
    propMinWidth,
    background,
    padding,
    color,
    isSecondary,
    isSubmit,
}) => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonTextStyle: CSSProperties = useMemo(() => ({
        width: propWidth,
        display: propDisplay,
        minWidth: propMinWidth,
        color: isSecondary ? '#000' : color,
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        transition: 'transform 0.3s ease, background-color 0.3s ease',
    }), [propWidth, propDisplay, propMinWidth, color, isSecondary, isHovered]);

    const buttonStyle: CSSProperties = useMemo(() => ({
        background: isSecondary ? (isHovered ? '#FFFFFF' : '#FFF') : (isHovered ? '#009BFF' : background),
        padding: padding,
        border: isSecondary ? '1px solid #000' : 'none',
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        transition: 'transform 0.3s ease, background-color 0.3s ease',
    }), [background, padding, isSecondary, isHovered]);


    return (
        <button
            type={`${isSubmit ? 'submit' : 'button'}`}
            className={`cursor-pointer py-6 px-9 ${isSecondary ? 'bg-white text-black' : 'bg-royalblue text-white'} rounded-21xl flex flex-row items-start justify-start gap-[8px]`}
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <img
                className="h-[8px] relative shrink-0 hidden min-h-[8px]"
                alt=""
                src={lineRoundedSearch}
            />
            <span
                className="relative text-lg leading-[18px] text-center"
                style={buttonTextStyle}
            >
                {buttonText}
            </span>
            <img
                className="h-[8px] relative shrink-0 hidden min-h-[8px]"
                alt=""
                src={lineRoundedArrowRight}
            />
        </button>
    );
};

export default PrimaryButton;
